const parseStringPromise = require('xml2js').parseStringPromise;

export default {
    parseProgramGroups(result) {
        if (!result || !result.category) return [];

        let groups = result.category;
        let parsed = [];

        groups.forEach((group) => {
            parsed.push(this.parseProgramGroup(group));
        });

        return parsed;
    },

    parseLiveStreams(result) {
        return this.parseVideos(result, true, false);
    },

    parseScheduledPlayout(result) {
        return this.parseVideos(result, false, true);
    },

    parseVideos(result, livestream, schedule) {
        let prop = 'video';

        if (livestream) prop = 'livestream';
        else if (schedule) prop = 'scheduledplayout';

        if (!result || !result[prop]) return [];

        let videos = result[prop];
        let parsed = [];

        videos.forEach((video) => {
            parsed.push(this.parseVideo(video));
        });

        return parsed;
    },

    parseFile(file) {
        let parsed = {
            url: file[0].$.url,
            bitrate: file[0].$.bitrate,
            contentid: file[0].$.contentid,
            quality: file[0].$.quality,
        };

        return parsed;
    },

    parseProgramGroup(group) {
        let videos =
            group.videos && group.videos[0] ? parseInt(group.videos[0]) : 0;
        let subGroups =
            group.subcategories && group.subcategories[0]
                ? parseInt(group.subcategories[0])
                : 0;
        let livestreams =
            group.livestreams && group.livestreams[0]
                ? parseInt(group.livestreams[0])
                : 0;

        let parsed = {
            id: group.$.id,
            parentId: (group.parent && group.parent[0]) || null,
            type: 'group',
            title: (group.title && group.title[0]) || '',
            subtitle: (group.subtitle && group.subtitle[0]) || '',
            description: (group.description && group.description[0]) || '',
            webDescription:
                (group.webdescription && group.webdescription[0]) ||
                (group.description && group.description[0]) ||
                '',
            image: (group.image && group.image[0]) || '',
            fhdImage: (group.fhdimage && group.fhdimage[0]) || '',
            displayPosterTitle:
                !!group.displaythumbnailtitle &&
                group.displaythumbnailtitle[0] === '1',
            playall: !!group.playall && group.playall[0] === '1',
            loop: !!group.playallloop && group.playallloop[0] === '1',
            shuffle: !!group.playallshuffle && group.playallshuffle[0] === '1',
            childCount: subGroups + videos + livestreams,
            videoCount: videos + livestreams,
            subGroupCount: subGroups,
            hasUserSuggestions:
                !!group.suggestions && group.suggestions[0] === '1',
            subscriberBehavior:
                (!!group.subscriberbehavior && group.subscriberbehavior[0]) ||
                'allow',
            freeBehavior:
                (!!group.freebehavior && group.freebehavior[0]) || 'allow',
            rowStyle: group.rowstyle?.[0] || 'default',
        };

        return parsed;
    },

    parseVideo(video) {
        let parsed = {
            id: video.$.id,
            parentId: (video.parent && video.parent[0]) || null,
            scheduleId: (video.liveplayout && video.liveplayout[0]) || null,
            type: video.type && video.type[0] === 'audio' ? 'audio' : 'video',
            streamFormat: (video.streamformat && video.streamformat[0]) || '',
            length:
                video.length &&
                (video.length[0] ? parseInt(video.length[0]) : 0),
            watched: !!video.watched && video.watched[0] === '1',
            bookmark:
                video.bookmark &&
                (video.bookmark[0] ? parseInt(video.bookmark[0]) : 0),
            premium: !!video.premium && video.premium[0] === '1',
            subscriberBehavior:
                (!!video.subscriberbehavior && video.subscriberbehavior[0]) ||
                'allow',
            freeBehavior:
                (!!video.freebehavior && video.freebehavior[0]) || 'allow',
            hasAds: !!video.ad && video.ad[0] === '1',
            title:
                (video.title && video.title[0].replace(/<!--(.*?)-->/gm, '')) ||
                '',
            subtitle:
                (video.subtitle &&
                    video.subtitle[0].replace(/<!--(.*?)-->/gm, '')) ||
                '',
            nowPlaying: !!video.nowplaying && video.nowplaying[0] === '1',
            logo: video.logo?.[0],
            logoPosition: video.logoposition?.[0],
            overlayText: video.overlayText?.[0],
            description: (video.description && video.description[0]) || '',
            adCuePoints: video.adcuepoints && video.adcuepoints[0],
            webDescription:
                (video.webdescription && video.webdescription[0]) ||
                (video.description && video.description[0]) ||
                '',
            image: (video.image && video.image[0]) || '',
            backgroundImage:
                (video.backgroundimage && video.backgroundimage[0]) || '',
            backgroundColor:
                (video.backgroundcolor && video.backgroundcolor[0]) || '',
            fhdImage: (video.fhdimage && video.fhdimage[0]) || '',
            views: (video.views && parseInt(video.views[0])) || 0,
            displayPosterTitle:
                !!video.displaythumbnailtitle &&
                video.displaythumbnailtitle[0] === '1',
            live: !!video.live && video.live[0] === '1',
            added: video.added && video.added[0],
            playImmediately:
                !!video.playimmediately && video.playimmediately[0] === '1',
            files: [],
            links: [],
            subtitleTracks: [],
        };

        if (video.subtitletracks[0]?.track?.length) {
            const tracks = video.subtitletracks[0].track;
            tracks.forEach((t) => {
                parsed.subtitleTracks.push({
                    src: t.$.trackname,
                    srclang: t.$.language,
                });
            });
        }
        let description = parsed.webDescription || parsed.description;
        description = description || '';
        description = description.replace(/(?:\r\n|\r|\n)/g, '<br>');
        description = description.replace(/<\s*img(.*?)<\/\s*img\s*>/gm, '');
        parsed.webDescription = description.replace(
            /<\s*script(.*?)<\/\s*script\s*>/gm,
            '',
        );

        parsed.trailer = video.trailer
            ? this.parseVideo(video.trailer[0])
            : null;
        parsed.preRoll = video.preroll
            ? this.parseVideo(video.preroll[0])
            : null;
        parsed.postRoll = video.postroll
            ? this.parseVideo(video.postroll[0])
            : null;

        if (video.link) {
            video.link.forEach((link) => {
                parsed.links.push(link.$);
            });
        }

        if (video.files) {
            video.files.forEach((file) => {
                parsed.files.push(this.parseFile(file.file));
            });
        }

        return parsed;
    },

    async parseProgramItems(xml) {
        const items = await parseStringPromise(xml).then((data) => {
            data = data.response;

            let parsedData = this.parseProgramGroups(data)
                .concat(this.parseLiveStreams(data))
                .concat(this.parseScheduledPlayout(data))
                .concat(this.parseVideos(data));

            return parsedData;
        });

        return items;
    },

    async parseTerms(xml) {
        const data = await parseStringPromise(xml);
        const res = data.response;
        let terms = res.termsofuse || res.privacypolicy;
        let parsedTerms;

        terms.forEach((term) => {
            if (term?.$?.platform === 'web') {
                parsedTerms = term._;
                return;
            }
        });

        return parsedTerms;
    },
};
